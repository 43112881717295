// @flow
import React from 'react';

import Layout from '../components/Layout';
import Link from '../components/Link';
import Markdown from '../components/Markdown';
import SectionSingleColumn from '../components/SectionSingleColumn';
import strings from '../config/strings.json';

class Page404 extends React.Component {
  render() {
    const {header, content, pageMeta} = strings.pages['404'];
    return (
      <Layout path="/404" pageMeta={{strings: pageMeta}}>
        <SectionSingleColumn header={header}>
          <Markdown content={content} />
          <p>
            <Link to="/">&larr; Head back to the homepage</Link>
          </p>
        </SectionSingleColumn>
      </Layout>
    );
  }
}

export default Page404;
